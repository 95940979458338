<template>
  <div>
    <b-modal
      @show="showModal"
      @hidden="hideModal"
      id="my-modal"
      ref="my-modal"
      size="lg"
      class="rounded-0"
      centered
      no-close-on-backdrop
      :title="'Bilet İşlemleri'"
    >
      <b-card-text class="px-5">
        <div
          style="max-width: 600px; font-family: Arial, Helvetica, sans-serif; font-size: 16px"
          v-if="Object.keys(updateData).length > 0"
          ref="document"
        >
          <div
            style="
              padding: 20px;
              color: white;
              border-top-left-radius: 30px;
              border-top-right-radius: 30px;
              max-width: 600px;
              background: linear-gradient(320deg, #171263 0%, #7066ff 48%, #171263 100%);
            "
          >
            <table style="width: 100%">
              <tr>
                <td><img src="http://localhost:6807/uploads/logo/image_280159007.png" style="width: 150px" /></td>
                <td style="float: right; text-align: right; padding-top: 10px">
                  <span>Rezervasyon Tarihi :</span>
                  <span style="font-weight: bold">{{ updateData.tarih }}</span>
                  <br />
                  <br />
                  <span>Rezervasyon Seans :</span>
                  <span style="font-weight: bold">{{ updateData.seans_alis_saat }}</span>
                </td>
              </tr>
            </table>
            <!-- Misafir bilgisi  -->
            <table style="width: 100%; padding-top: 80px; margin-top: 40px; font-size: 24px">
              <tr>
                <td>
                  <span>Syn, {{ updateData.ad }} {{ updateData.soyad }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span style="padding-top: 10px; font-size: 16px">{{ updateData.urun['tr'].urun_adi }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span style="padding-top: 10px; font-size: 16px">
                    {{ updateData.yetiskin_pax }} Yetiskin {{ updateData.cocuk_pax }} Cocuk {{ updateData.bebek_pax }} Bebek
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <div style="border: 1px solid #7066ff; padding: 20px; border-bottom-right-radius: 60px; background: #fefefe">
            <table style="border-bottom: 1px solid #7066ff; width: 100%; padding-bottom: 30px">
              <tr>
                <td style="color: #0b018f; font-weight: bold">Rezervasyon No</td>
                <td>:</td>
                <td>{{ updateData.k_no }}</td>
              </tr>
              <tr>
                <td style="color: #0b018f; font-weight: bold">Hotel Adı</td>
                <td>:</td>
                <td>{{ updateData.hotel_adi }}</td>
              </tr>
              <tr>
                <td style="color: #0b018f; font-weight: bold">Hotel Oda No</td>
                <td>:</td>
                <td>{{ updateData.hotel_oda_no }}</td>
              </tr>
              <tr>
                <td style="color: #0b018f; font-weight: bold">Hotel Adres</td>
                <td>:</td>
                <td>{{ updateData.hotel_adres }}</td>
              </tr>
              <tr>
                <td style="color: #0b018f; font-weight: bold">Bakiye:</td>
                <td>:</td>
                <td style="font-weight: bold; color: #8f3701">
                  {{ updateData.secilen_kur_toplam | cur }} {{ updateData.secilen_kur }}
                </td>
              </tr>
              <tr>
                <td style="color: #0b018f; font-weight: bold">Odeme Tipi</td>
                <td>:</td>
                <td>{{ odemeText(updateData.odeme_yontemi) }}</td>
              </tr>
              <tr>
                <td style="color: #0b018f; font-weight: bold">Tahsilat Durum</td>
                <td>:</td>
                <td :style="`color:${odemeTahsilColor(updateData.odeme_durum)}`">
                  {{ updateData.odeme_durum ? 'Tahsil Edildi' : 'Beklemede' }}
                </td>
              </tr>
            </table>

            <div style="padding: 20px; text-align: center">
              Side Street No: 509 Manavgat <br />
              Antalya TÜRKİYE ZIP <br />
              07330 +90 536 603 75 84, muratatalay0721@gmail.com
            </div>

            <div style="padding: 20px; text-align: center">
              <img src="https://www.imay.com.tr/images/21295280302074126239" width="200" />
            </div>
          </div>
        </div>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" type="submit" @click="handlerDownloadToPdf" :disabled="show">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Yazdır
          </b-button>
          <b-button squared variant="success" class="float-right mr-1" @click="biletGonder(updateData)" :disabled="show">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Gönder
          </b-button>
          <b-button squared variant="danger" class="float-right mr-1" @click="hideModal">
            <feather-icon class="cursor-pointer" icon="Trash2Icon" size="16" />
            İptal
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import vSelect from 'vue-select';
import axiosIns from '@/libs/axios';
export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statuText() {
      const statusText = {
        1: 'Onay Bekliyor',
        2: 'Değişiklik İsteği',
        3: 'Ücret İade İsteği',
        4: 'Onaylandı',
        5: 'İptal Edildi',
      };

      return (status) => statusText[status];
    },
    statuColor() {
      const statusColor = {
        1: '#1377d3',
        2: '#ffc107',
        3: '#DA4E8F',
        4: '#20c997',
        5: '#ea5455',
      };

      return (status) => statusColor[status];
    },
    odemeText() {
      const statusText = {
        1: 'Kapıda Ödeme',
        2: 'Havale EFT',
        3: 'Kredi Kartı',
      };

      return (status) => statusText[status];
    },
    odemeColor() {
      const statusColor = {
        1: '#1377d3',
        2: '#20c997',
        3: '#ff9f43',
      };

      return (status) => statusColor[status];
    },

    odemeTahsilColor() {
      const statusColor = {
        true: '#20c997',
        false: '#ea5455',
      };

      return (status) => statusColor[status];
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {},
  methods: {
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$nextTick(() => {
        this.$bvModal.hide('my-modal');
      });
      this.$emit('closeModal', true);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    handlerDownloadToPdf() {
      window.print();
    },

    async biletGonder(payload) {
      this.show = true;
      await axiosIns
        .post('/rezervasyon/rezervasyon-bilet-gonder', payload)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: 'Bilet iletilmiştir.',
              },
            });
            this.show = false;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Uyarı`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text: 'Bir hata oluştu.',
              },
            });
            this.show = false;
          }
        })
        .catch((err) => {
          this.show = false;
          if (err.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
  },
  watch: {
    openModal: {
      handler(val) {
        if (val == true) {
          this.showModal();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
